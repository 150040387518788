import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-wrapper fadeInUp">
        <p className="footer-text">© 2021 by Netribbon.</p>
        <div className="social-icon">
          <a
            href="https://www.facebook.com/Netribbon-107591431366745/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            href="https://twitter.com/netribbon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter-square"></i>
          </a>
        </div>
      </section>
    </div>
  );
}

export default Footer;
