import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="about-container fadeInUp">
      <h1 className="ah1">ABOUT US</h1>
      <p className="ap1">
        Netribbon provides digital solutions to shape up your IT world.
        Headquartered in Toronto, the North American Fin-tech market is our
        focus. Our technologies are proven to resolve your IT obstacles, enable
        new growth, and protect your systems for all the customers.
      </p>
      <p className="ap2">
        Netribbon has a track record of success and is proud that customers
        maintain ongoing relations, and forge long-term relationships with the
        Netribbon technical team.
      </p>
      <p className="ap3">
        ​We are looking forward to helping you achieving your goals in absolute
        best way possible.
      </p>
    </div>
  );
}

export default AboutUs;
