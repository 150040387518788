import React, { Component } from "react";
import "./Contact.css";

class Contact extends Component {
  state = {};

  render() {
    return (
      <div id="contact" className="contact">
        <h1 className="contact-title">CONTACT US</h1>
        <div className="contact-container">
          <a
            href="https://www.google.com/maps/place/227+Vodden+St+E,+Brampton,+ON+L6V+1N2/@43.7023901,-79.7584737,17z/data=!3m1!4b1!4m5!3m4!1s0x882b15ef576c6095:0xc62c28aec3a37b5f!8m2!3d43.7023901!4d-79.756285"
            target="_blank"
            rel="noopener noreferrer"
            className="map-image"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/ggmap01.jpg"}
              alt="ggmap"
              className="map-image1"
            ></img>
          </a>

          <div className="form-container">
            <section className="address">
              <p>227 Vodden St E, Brampton, ON L6V 3C9,</p>
              <p>Canada</p>
              <p>info@netribbon.com</p>
              <p>Tel: 416-615-3453</p>
            </section>
            {/* <form action="/" className="form-input" method="POST">
              <input
                className="input-class"
                type="text"
                name="name"
                placeholder=" Name"
              />
              <input
                className="input-class"
                type="text"
                name="email"
                placeholder=" Email"
              />
              <input
                className="input-class"
                type="text"
                name="subject"
                placeholder=" Subject"
              />
              <textarea
                className="input-class message"
                type="text"
                name="message"
                placeholder=" Type your message here..."
              />

              <button className="submit-button" type="submit">
                Submit
              </button>
            </form> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
